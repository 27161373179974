import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { ButtonsCompact } from './components/ButtonsCompact';
import { Center } from './components/Center';
import { Paths } from './components/paths';
import { Toggle } from './components/Toggle';
import { useMobile } from './hooks/useMobile';
import { NavButtons } from './components/NavButtons';
import { ButtonsMobile } from './components/ButtonsMobile';

export function App() {
  const mobile = useMobile();
  const s = useStyles();

  return (
    <div className={cx('app', s.base)}>
      <Toggle on={!mobile} animation='slide'>
        <Center horizontal>
          <ButtonsCompact>
            <NavButtons />
          </ButtonsCompact>
        </Center>
        <div style={{ height: 10 }} />
      </Toggle>

      <div className={cx(s.spacer, mobile && 'grow')} />
      <Paths className={cx(s.spacer, !mobile && 'grow')} />
      <Toggle on={mobile} animation='slide'>
        <ButtonsMobile>
          <NavButtons />
        </ButtonsMobile>
      </Toggle>
    </div>
  );
}

const useStyles = createUseStyles({
  base: {
    paddingTop: 25,
    // backgroundColor: '#444',
    boxSizing: 'border-box',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },

  spacer: {
    transition: 'flex 0.4s ease',

    '&.grow': {
      flexGrow: 1
    },
  },
});
