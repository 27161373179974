import { createUseStyles } from 'react-jss';
import { PointerPad } from '../components/PointerPad';
import { sendCommand } from '../tools/backend';


export function Controls() {
  const s = useStyles();

  const onMove = (x: number, y: number) => {
    sendCommand('mousedelta', { x: x * 2, y: y * 2 });
  };

  return (
    <div className={s.controls}>
      <PointerPad
        moveDelay={50}
        onClick={() => sendCommand('input', '[LButton]')}
        onMoveDelta={onMove}
      />
    </div>
  );
}

const useStyles = createUseStyles({
  controls: {
    width: '100vw',
    marginBottom: 10,
  }
});