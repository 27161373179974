import { Navigate, Route, Routes } from 'react-router-dom';
import { Audio } from '../views/Audio';
import { Extras } from '../views/Extras';
import { Games } from '../views/Games';
import { Home } from '../views/Home';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';
import { Controls } from '../views/Controls';

type Props = {
  className?: string;
};

export function Paths(p: Props) {
  const s = useStyles();

  return (
    <div className={cx(p.className, s.padding, s.centering)}>
      <Routes>
        <Route path='/' element={<Navigate to='/app/home' />} />
        <Route path='/app/home' element={<Home />} />
        <Route path='/app/audio' element={<Audio />} />
        <Route path='/app/extra' element={<Extras />} />
        <Route path='/app/games' element={<Games />} />
        <Route path='/app/controls' element={<Controls />} />
      </Routes>
    </div>
  );
}

const useStyles = createUseStyles({
  padding: {
    padding: '0 10px',
  },

  centering: {
    display: 'flex',
    justifyContent: 'center',
  }
});