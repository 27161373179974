import { useNavigate } from 'react-router-dom';
import { Button } from './Button';

export function NavButtons() {
  const navigate = useNavigate();
  
  return (
    <>
      <Button text='Home' onClick={() => navigate('/app/home')} />
      <Button text='Audio' onClick={() => navigate('/app/audio')} />
      <Button text='Extra' onClick={() => navigate('/app/extra')} />
      <Button text='Games' onClick={() => navigate('/app/games')} />
      <Button text='Controls' onClick={() => navigate('/app/controls')} />
    </>
  );
}