import { ReactNode } from 'react';
import { createUseStyles } from 'react-jss';
import { useMobile } from '../hooks/useMobile';
import cx from 'classnames';

export type BContainerProps = {
  children: ReactNode | ReactNode[];
};

export function ButtonContainer(p: BContainerProps) {
  const mobile = useMobile();
  const s = useStyles();
  
  return (
    <div className={cx(s.container, !mobile && 'widen')}>
      {p.children}
    </div>
  );
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 10,
    
    '& button': {
      marginRight: 10,
      flex: '1 1 100px',
      
      '&:last-child': {
        marginRight: 0,
      },
    },
    
    '&.widen button': {
      flex: '1 0 100px',
    },
  }
});
