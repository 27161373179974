import { sendCommand } from '../tools/backend';
import { Button } from '../components/Button';
import { ButtonContainer } from '../components/ButtonContainer';

export function Games() {
  return (
    <div id='games'>
      <ButtonContainer>
        <Button text='SteamVR' onClick={() => sendCommand('steamvr')} />
        <Button text='Elden Ring' onClick={() => sendCommand('steam_game', '1245620')} />
      </ButtonContainer>
    </div>
  );
}