import { createUseStyles } from 'react-jss';
import cx from 'classnames';
import { useMobile } from '../hooks/useMobile';
import { useTouch } from '../hooks/useTouch';

export type ButtonProps = {
  text: string;
  onClick?: () => void;
  disabled?: boolean;
};

export function Button(p: ButtonProps) {
  const mobile = useMobile();
  const touch = useTouch();
  const styles = useStyles();

  return (
    <button onClick={p.onClick} disabled={p.disabled} className={cx(styles.button, p.disabled && styles.hide, mobile && 'mobile', touch && 'touch')}>
      {p.text}
    </button>
  );
}

const useStyles = createUseStyles({
  button: {
    outline: 'none',
    fontSize: '1em',
    padding: '0.6em',
    transition: 'background-color 100ms ease, filter 200ms ease, flex 400ms ease',

    backgroundColor: '#fffb',
    border: '1px solid #3333',
    borderRadius: 5,
    cursor: 'pointer',
    fontFamily: 'Arial, Helvetica, sans-serif',
    flex: 1,

    '&.mobile': {
      transition: 'background-color 100ms ease, filter 200ms ease',
    },

    '&:not(.touch)': {
      '&:hover': {
        backgroundColor: '#ddd'
      }
    },

    '&:active': {
      filter: 'brightness(0.9)'
    }
  },

  hide: {
    opacity: 0,
    pointerEvents: 'none'
  }
});
