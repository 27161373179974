import { ReactNode } from 'react';
import { createUseStyles } from 'react-jss';

export type Props = {
  children: ReactNode | ReactNode[];
};

export function ButtonsMobile(p: Props) {
  const styles = useStyles();
  
  return (
    <div className={styles.buttons}>
      {p.children}
    </div>
  );
}

const useStyles = createUseStyles({
  buttons: {
    width: '100%',
    display: 'flex',
    marginTop: 20,
    
    '& button': {
      borderRight: 'none',
      borderLeft: 'none',
      borderRadius: 0,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      flex: '1 0 auto',
      height: 50,
      
      '&:not(:first-child)': {
        borderTopLeftRadius: 0,
        borderWidth: '1px 1px 1px 0px',
      },
      
      '&:not(:last-child)': {
        borderTopRightRadius: 0,
      }
    }
  }
});