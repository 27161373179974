import { ReactNode } from 'react';
import { createUseStyles } from 'react-jss';

export type ButtonsCompactProps = {
  children: ReactNode | ReactNode[];
};

export function ButtonsCompact(p: ButtonsCompactProps) {
  const styles = useStyles();
  
  return (
    <div className={styles.buttons}>
      {p.children}
    </div>
  );
}

const useStyles = createUseStyles({
  buttons: {
    '& button': {
      
      '&:not(:first-child)': {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderWidth: '1px 1px 1px 0px',
      },
      
      '&:not(:last-child)': {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0
      }
    }
  }
});