import { createUseStyles } from 'react-jss';

export type BHeaderProps = {
  text: string;
};

export function ButtonHeader(p: BHeaderProps) {
  const styles = useStyles();

  return (
    <div className={styles.header}>
      {p.text}
    </div>
  );
}

const useStyles = createUseStyles({
  header: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'center',
    color: '#ddd',
    fontSize: 18,
    fontFamily: 'Arial'
  }
});