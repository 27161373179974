import { useRef } from 'react';
import { createUseStyles } from 'react-jss';

type Props = {
  moveDelay?: number;
  onClick?: (x: number, y: number) => void;
  onDown?: (x: number, y: number) => void;
  onUp?: (x: number, y: number) => void;
  onMove?: (x: number, y: number) => void;
  onMoveDelta?: (x: number, y: number) => void;
};

const moveThreshold = 10;
type PointerState = typeof initialState;
const initialState = {
  isDown: false,
  lastMove: 0,
  delta: { x: 0, y: 0 },
  distance: { x: 0, y: 0, },
};

export function PointerPad(p: Props) {
  const s = useStyles();
  const ref = useRef<PointerState[]>([]);

  const pointerDown = (e: React.PointerEvent) => {
    e.preventDefault();
    e.stopPropagation();
    (e.target as any).setPointerCapture(e.pointerId);
    if (!ref.current[e.pointerId])
      ref.current[e.pointerId] = { ...initialState };
    ref.current[e.pointerId].isDown = true;
    ref.current[e.pointerId].distance = { x: 0, y: 0, };
    ref.current[e.pointerId].delta = { x: 0, y: 0, };

    const pos = getRelativePosition(e.currentTarget as HTMLElement, e.clientX, e.clientY);
    p.onDown?.(pos.x, pos.y);
  };

  const pointerUp = (e: React.PointerEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (!ref.current[e.pointerId].isDown)
      return;
    ref.current[e.pointerId].isDown = false;
    const pos = getRelativePosition(e.currentTarget as HTMLElement, e.clientX, e.clientY);
    p.onUp?.(pos.x, pos.y);
    if (Math.max(ref.current[e.pointerId].distance.x, ref.current[e.pointerId].distance.y) < moveThreshold)
      p.onClick?.(pos.x, pos.y);
  };

  const pointerMove = (e: React.PointerEvent) => {
    e.preventDefault();
    e.stopPropagation();
    
    if (!ref.current[e.pointerId].isDown)
      return;
    ref.current[e.pointerId].distance.x += Math.abs(e.movementX);
    ref.current[e.pointerId].distance.y += Math.abs(e.movementY);
    ref.current[e.pointerId].delta.x += e.movementX;
    ref.current[e.pointerId].delta.y += e.movementY;
    
    if (Math.max(ref.current[e.pointerId].distance.x, ref.current[e.pointerId].distance.y) < moveThreshold)
      return;
    if (Date.now() - ref.current[e.pointerId].lastMove < (p.moveDelay ?? 0))
      return;
    const pos = getRelativePosition(e.currentTarget as HTMLElement, e.clientX, e.clientY);
    p.onMove?.(pos.x, pos.y);
    p.onMoveDelta?.(ref.current[e.pointerId].delta.x, ref.current[e.pointerId].delta.y);
    ref.current[e.pointerId].delta = { x: 0, y: 0 };
  };

  return (
    <div className={s.pad}
      onPointerDown={pointerDown}
      onPointerUp={pointerUp}
      onPointerMove={pointerMove}
    />
  );
}

function getRelativePosition(element: HTMLElement, x: number, y: number) {
  const rect = element.getBoundingClientRect();
  return {
    x: x - rect.left,
    y: y - rect.top,
  };
}

const useStyles = createUseStyles({
  pad: {
    backdropFilter: 'blur(5px)',
    width: '100%',
    height: '100%',
    borderRadius: 'max(2vw, 2vh)',
    touchAction: 'none',
  }
});