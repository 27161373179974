import { CSSProperties, ReactNode } from 'react';
import { createUseStyles } from 'react-jss';
import cx from 'classnames';

export type CenterProps = {
  children: ReactNode | ReactNode[];
  className?: string;
  style?: CSSProperties;
  vertical?: boolean;
  horizontal?: boolean;
};

export function Center(p: CenterProps) {
  const s = useStyles();
  
  return (
    <div className={cx(p.className, s.center, p.vertical && s.v, p.horizontal && s.h)} style={p.style}>
      {p.children}
    </div>
  );
}

const useStyles = createUseStyles({
  center: {
    display: 'flex'
  },
  
  v: {
    alignItems: 'center'
  },
  
  h: {
    justifyContent: 'center'
  },
});