import axios from 'axios';
import { isLaunchType, LaunchType } from 'shared';

export function sendNotification(msg: string) {
    const data = { data: msg };
    axios.post('/api/notification', data);
}

export function sendCommand(command: string, data?: string | object) {
    if (typeof data === 'object')
        data = JSON.stringify(data);
    axios.post('/api/command', { command, data: data ?? '' });
}

export function setTvState(state: boolean) {
    axios.post('/api/tv', { state });
}

export function merossToggle(name: string) {
    axios.post('/api/meross/toggle', { name });
}

export function merossSet(name: string, state: boolean) {
    axios.post('/api/meross/set', { name, state });
}

export function iftttEvent(...events: string[]) {
    axios.post('/api/ifttt', events);
}

export function pcWake() {
    axios.post('/api/wake');
}

export function systemRestart(mode: LaunchType) {
    if (!isLaunchType(mode))
        throw new Error('Bad argument for systemRestart(mode)');
    axios.post('/api/restart', { type: mode });
}