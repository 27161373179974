import { sendCommand } from '../tools/backend';
import { Button } from '../components/Button';
import { ButtonContainer } from '../components/ButtonContainer';
import { ButtonHeader } from '../components/ButtonHeader';

export function Extras() {
  return (
    <div id='extra'>
      <ButtonContainer>
        <Button text='Screen Share' onClick={() => sendCommand('share')} />
        <Button text='Toggle Share Volume' onClick={() => sendCommand('toggle_share_fix')} />
        <Button text='Disable Docker' onClick={() => sendCommand('disable_docker')} />
      </ButtonContainer>
      
      <ButtonHeader text='Desktop' />
      <ButtonContainer>
        <Button text='' disabled />
        <Button text='Up' onClick={() => sendCommand('desktop_up')} />
        <Button text='' disabled />
      </ButtonContainer>
      
      <ButtonContainer>
        <Button text='Left' onClick={() => sendCommand('desktop_left')} />
        <Button text='Down' onClick={() => sendCommand('desktop_down')} />
        <Button text='Right' onClick={() => sendCommand('desktop_right')} />
      </ButtonContainer>
      
      <ButtonHeader text='Monitor Orientation' />
      <ButtonContainer>
        <Button text='Horizontal' onClick={() => sendCommand('monitor_horizontal')} />
        <Button text='Vertical' onClick={() => sendCommand('monitor_vertical')} />
      </ButtonContainer>
    </div>
  );
}